import React, {Component} from 'react';

import axios from 'axios';

import { connect } from 'react-redux';
import { fetchEntries } from '../../../actions/contentfulAction';

import Hero from './components/Hero/Hero';
import Partners from '../../Common/Partners/Partners';
import Introduction from './components/Introduction/Introduction';
import ChallengeOutcomes from './components/ChallengeOutcomes/ChallengeOutcomes';
import CallToAction from '../../Common/CallToAction/CallToAction';
import ChallengeAreas from './components/ChallengeAreas/ChallengeAreas';
import Subscription from './components/Subscription/Subscription';
import Footer from '../../Common/Footer/Footer';
import Spinner from '../../Common/Spinner/Spinner';

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const landingPageUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchLandingPageData';

    axiosInstance
      .get(landingPageUrl)
      .then((result) => {
        this.setState({
          data: result.data.data.pageLayoutCollection.items[0].contentModulesCollection.items})
      })
      .catch(console.error);
  }

  render() {
    if (this.state.data.length) {
      let ctaData = this.state.data[3];
      ctaData.navigateTo = '/competition';
      ctaData.buttonText = 'learn more'
      return (
        <div>
          <Hero heroData={this.state.data[0]} />
          <Partners />
          <Introduction introData={this.state.data[1]} />
          <ChallengeOutcomes outcomeData={this.state.data[2]} />
          <CallToAction ctaData={ctaData} />
          <ChallengeAreas challengeData={this.state.data[5]} />
          <Subscription subscriptionData={this.state.data[6]} />
          <Footer />
        </div>
      );
    }
    return (
      <Spinner />
    );
  }
}

const mapStateToProps = (state) => {
  return { entries: state.entries };
}

export default connect(mapStateToProps, {fetchEntries} )(Home);

import React from 'react';
import './Registration.scss';

import UniStudent from '../UniStudent/UniStudent';
import UniList from '../UniList/UniList';

class Registration extends React.Component {
  constructor(props) {
    super(props)
    this.state = { active: 'FIRST_FORM' }
  }

  triggerShowNextForm = (form) => {
    this.setState({
      active: form
    })
  }

  render() {
    return (
      <section className='registration'>
        <form>
          {this.state.active === 'FIRST_FORM' && <UniStudent nextForm={this.triggerShowNextForm} />}
          {this.state.active === 'SECOND_FORM' && <UniList nextForm={this.triggerShowNextForm} />}
        </form>
      </section>
    )
  }
}

export default Registration;

import React from 'react';
import * as Button from '../../../../Common/Button/Button';

import Modal from '../../../../Common/Modal/Modal';

class UniStudent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }

  handleNo = () => {
    const notAStudent = document.querySelector('.not-a-student');
    const noRadioButton = document.getElementById('no');
    if (noRadioButton.checked) {
      notAStudent.classList.remove('hide');
    }
  }

  handleYes = () => {
    const notAStudent = document.querySelector('.not-a-student');
    const yesRadioButton = document.getElementById('yes');
    if (yesRadioButton.checked) {
      notAStudent.classList.add('hide');
    }
  }

  showModal = () => {
    this.setState({ show: true });
    // setTimeout(function(){ window.location = 'https://www.plusacumen.org'; }, 5000);
  }

  hideModal = () => {
    this.setState({ show: false });
  };

  handleButtonSubmit = () => {
    const yesRadioButton = document.getElementById('yes');
    const secondYesButton = document.getElementById('second-choice-yes');
    const noRadioButton = document.getElementById('no');
    const secondNoButton = document.getElementById('second-choice-no');

    if (yesRadioButton.checked || secondYesButton.checked) {
      this.props.nextForm('SECOND_FORM')
    } else if (noRadioButton.checked && secondNoButton.checked) {
      this.showModal();
      setTimeout(function () { window.location = 'https://www.plusacumen.org'; }, 5000);
    }
  }

  render() {
    return (
      <div>
        <p>Are you a university student?</p>
        <Modal show={this.state.show} handleClose={this.hideModal}
          children={
            <div className='container'>
              <div className='row'>
                <div className='col-m-11'>
                  <p>
                    Sorry, this competition is only for teams that have a university student. However, we have lots of
                    other opportunities for you at +Acumen!
                  </p>
                  <p>
                    We are re-directing you now to +Acumen...
                  </p>
                </div>
              </div>
            </div>
          }
        />
        <input onClick={this.handleYes} type="radio" name="choice" value="yes" id='yes' /> Yes<br />
        <input onClick={this.handleNo} type="radio" name="choice" value="no" id='no' /> No<br />
        <br />
        <div className='not-a-student hide'>
          <p>
            In order to participate in this competition, at least one person on your team must be enrolled at a university.
            Is at least one person on your team enrolled at a university?
          </p>
          <input onClick={this.handleYes} type="radio" name="second-choice" value="yes" id='second-choice-yes' /> Yes<br />
          <input onClick={this.handleNo} type="radio" name="second-choice" value="no" id='second-choice-no' /> No<br />
        </div>
        
        <Button.Blue buttonType='button' onClick={this.handleButtonSubmit} buttonText='continue' />
      </div>
    )
  }
}

export default UniStudent;

import React, { Component } from 'react';
import './SectorExperts.scss';

const renderExpertBios = (bios) => {
  const numberOfBios = bios.length;
  return bios.map((bio, index) => (
    <SectorBio length={numberOfBios} data={bio} index={index}/>
  ));
}

const SectorBio = (props) => {
  return (
    <div className='col-m-4'>
      <div className='container'>
      {
          ((props.index + 1) % 3 !== 0) &&
          <div className='inner-tile-box'></div>
      }
        <div className='row sector-experts__tile'>
          <div className={'col-m-5 inner-box '}>
            <img src={props.data.expertPhoto.url} alt={props.data.imageAltText} />
          </div>
          <div className='col-m-7'>
            <h3>{props.data.name}</h3>
            <p>{props.data.title}</p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
}

export default class SectorExperts extends Component {

  render() {
    return (
      <section className='sector-experts'>
        <div className='container'>
          <div className='row'>
            <div className='col-m-12'>
              <h2 className='text-center'>{this.props.expertData.header}</h2>
              <p className='sector-experts__intro'>
                {this.props.expertData.subHeader.json.content[0].content[0].value}
              </p>
            </div>
              {renderExpertBios(this.props.expertData.expertBiosCollection.items)}
          </div>
        </div>
      </section>
    )
  }
}

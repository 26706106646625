import React, { Component } from 'react';
import './Competition.scss';

import Markdown from 'react-markdown';

import * as Button from '../Button/Button';

class Competition extends Component {
  handleClick() {
    window.location = '/';
  }
  render() {
    return (
      <div className='competition'>
        <div className='container'>
          <div className='row'>
            <div className='col-m-12'>
              <h2>
                {this.props.competitionData.header}
            </h2>
              <img src={this.props.competitionData.image} alt={this.props.competitionData.imageAltText} />
            </div>
            <div className='col-m-6'>
              <h3>
                {this.props.competitionData.ctaHeader}
            </h3>
            <p>
              {this.props.competitionData.ctaText}
            </p>
              <Button.Blue onClick={this.handleClick} buttonText='registration closed' buttonSize='medium' />
            </div>
            <div className='col-m-6'>
              {
                this.props.competitionData.header === 'On Campus Competitions' && 
                <h1></h1>
              }
              <Markdown
                escapeHtml={true}
                source={this.props.competitionData.checklists}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Competition;

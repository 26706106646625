import React from 'react';
import './CallToAction.scss';

import * as Button from '../Button/Button';

const handleClick = (naviagateTo , newWindow) => {
  if (newWindow) {
    const win = window.open(naviagateTo, '_blank');
    win.focus();
  } else {
    window.location = naviagateTo;
  }
}

const CallToAction = (props) => (
  <section className='call-to-action-section'>
    <div className='container'>
      <div className='row'>
        <div className='col-m-12'>
          <h2>
            {props.ctaData.header}
          </h2>
          <p>
            {props.ctaData.callToActionText.json ? (props.ctaData.callToActionText.json.content[0].content[0].value) : (props.ctaData.callToActionText)}
          </p>
          <Button.Transparent onClick={(e) => handleClick(props.ctaData.navigateTo, props.ctaData.newWindow, e)} buttonText={props.ctaData.buttonText} buttonSize='large' />
        </div>
      </div>
    </div>
  </section>
);

export default CallToAction;

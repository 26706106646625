import React from 'react';
import './Footer.scss';

import FooterNav from './FooterNav/FooterNav';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <section className='footer'>
      <FooterNav />
      <div className='footer__bottom'>
        <div className='footer__left'>
          <a href='https://twitter.com/plusacumen' target='_blank' rel='noopener noreferrer'><i className='fab fa-twitter'></i></a>
          <a href='https://www.facebook.com/PlusAcumen/' target='_blank' rel='noopener noreferrer'><i className='fab fa-facebook-square'></i></a>
          <a href='https://www.linkedin.com/company/plusacumen' target='_blank' rel='noopener noreferrer'><i className='fab fa-linkedin'></i></a>
        </div>
        <div className='footer__right'>
          &copy; {currentYear}-Acumen | All Rights Reserved
      </div>
      </div>

    </section>
    );
}

export default Footer;

import React, { Component } from 'react';
import './WhoWeAre.scss';

import axios from 'axios'; 

import * as Button from '../../Common/Button/Button';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Spinner from '../../Common/Spinner/Spinner'

class WhoWeAre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  navigateToAcumen = () => {
    const win = window.open(this.state.data.acumenUrl, '_blank');
    win.focus();
  }

  navigateToRockefeller = () => {
    const win = window.open(this.state.data.rockefellerUrl, '_blank');
    win.focus();
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const whoWeAreUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchWhoWeArePageData';

    axiosInstance
      .get(whoWeAreUrl)
      .then((result) => {
        this.setState({
          data: result.data.data.pageLayoutCollection.items[0].contentModulesCollection.items[0]
        })
      })
      .catch(console.error);
  }

  render () {
    if (this.state.data.aboutAcumen) {
      return (
        <div className='who-we-are'>
          <Header />
          <div className='container'>
            <div className='row'>
              <div className='col-m-12'>
                <h1>Who we are</h1>
              </div>
            </div>
          </div>
          <section className='who-we-are__acumen'>
            <div className='who-we-are__image'>
              <img src={this.state.data.acumenImage.url} alt='people gathered around a table' />
            </div>
            <div className='who-we-are__text'>
              <div className='container'>
                <div className='row'>
                  <div className='col-m-12'>
                    <h2>Acumen</h2>
                    <p>
                      {this.state.data.aboutAcumen.json.content[0].content[0].value}
                    </p>
                    <Button.TransparentBlue onClick={this.navigateToAcumen} buttonText='learn more' />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className='who-we-are__rockefeller'>
            <div className='who-we-are__image'>
              <img src={this.state.data.rockefellerImage.url} alt='people gathered around a table' />
            </div>
            <div className='who-we-are__text'>
              <div className='container'>
                <div className='row'>
                  <div className='col-m-12'>
                    <h2>The Rockefeller Foundation</h2>
                    <p>
                      {this.state.data.aboutRockefeller.json.content[0].content[0].value}
                    </p>
                    <Button.TransparentBlue onClick={this.navigateToRockefeller} buttonText='learn more' />
                  </div>
                </div>
              </div>
            </div>
          </section>
          < Footer />
        </div>
      );
    }
    return <Spinner />
    }
}

export default WhoWeAre;

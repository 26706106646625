import React from 'react';
import './CompetitionIntro.scss';

import CompetitionLogo from './images/competition-logo.svg';

import * as Button from '../../../../Common/Button/Button';

const handleClick = (url) => {
  document.getElementById(url).scrollIntoView({
    behavior: 'smooth'
  });
}

const CompetitionIntro = (props) => {
  return (
    <section className='competition-intro'>
      <div className='container'>
        <div className='row'>
          <div className='col-m-12'>
            <div className='competition-intro__logo'>
              <img src={CompetitionLogo} alt='Acumen and The Rockefeller Foundation' />
            </div>
            <div className='competition-intro__header'>
              <h1>
                {props.introData.competitionHeader}
              </h1>
            </div>
            <div className='competition-intro__text'>
              <p>
                {props.introData.competitionInroText}
              </p>
            </div>
          </div>
          <div className='col-m-6 '>
            <div className='col-m-12 competition-intro__organization'>
              <h3>On Campus Competition</h3>
              <p>
                {props.onCampus.callToAction}
              </p>
              <Button.Transparent onClick={(e) => handleClick('campus-competitions', e)} buttonText='learn more' buttonSize='small' />
            </div>
          </div>
          <div className='col-m-6 '>
            <div className='col-m-12 competition-intro__organization'>
              <h3>Virtual Competition</h3>
              <p>
                {props.virtual.callToAction}
              </p>
              <Button.Transparent onClick={(e) => handleClick('virtual-competition', e)} buttonText='learn more' buttonSize='small' />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CompetitionIntro;

import React from 'react';
import './Challenge.scss';

import ChallengeIntro from './components/ChallengeIntro/ChallengeIntro';
import CallToAction from '../../Common/CallToAction/CallToAction';
import AdditionalResources from './components/AdditionalResources/AdditionalResources';
import SectorExperts from './components/SectorExperts/SectorExperts';
import OtherChallengeAreas from './components/OtherChallengeAreas/OtherChallengeAreas';

const BaseChallenge = props => {
  let ctaData = props.challengeData.challengeModulesCollection.items[1];
  ctaData.buttonText = 'download brief';
  ctaData.newWindow = true;
    return (
      <div>
        <ChallengeIntro data={props.challengeData} />
        <CallToAction ctaData={ctaData} />
        <AdditionalResources resources={props.challengeData.challengeModulesCollection.items[3]}/>
        <SectorExperts expertData={props.challengeData.challengeModulesCollection.items[2]}/>
        <OtherChallengeAreas currentChallenge={props.challengeData.title} setChallenge={props.setCurrentChallenge}/>
      </div>
    )
}

export const GlobalHealth = props => (
  <BaseChallenge {...props} />
);

export const FoodSystems = props => (
  <BaseChallenge {...props} />
);

export const Energy = props => (
  <BaseChallenge {...props} />
);

export const WorkforceDevelopment = props => (
  <BaseChallenge {...props} />
);

export const FinancialInclusion = props => (
  <BaseChallenge {...props} />
);

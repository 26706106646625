import React from 'react';
import './CampusCompetitions.scss';

import Competition from '../../../../Common/Competition/Competition';

const CampusCompetitions = (props) => {

  const competitionData = {
    header: 'On Campus Competitions',
    image: props.onCampus.competitionImage.url,
    imageAltText: 'Man talking to a lady and a gentleman',
    ctaHeader: 'Join Us on Campus',
    ctaText: props.onCampus.joinUsOnCampus,
    checklists: props.onCampus.competitionChecklist
  }
  return (
    <section id='campus-competitions' className='campus-competitions'>
      <Competition competitionData = {competitionData} />
    </section>
  );
}

export default CampusCompetitions;

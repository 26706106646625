import React, { Component } from 'react';
import './Subscription.scss';

import axios from 'axios';

import * as Button from '../../../../Common/Button/Button';

class Subscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
      loading: false,
      submitted: false,
      showForm: true,
    };
  }

  handleButtonSubmit = e => {

    this.setState({initial: false, loading: true})
    const formData = new FormData(e.target)
    let data = {}

    e.preventDefault()

    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }

    data = { user: data };

    const formDataUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/subscribeToNewsletter';

    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    axiosInstance
      .post(formDataUrl, data)
      .then((result) => {
        if (result.status === 200) {
          this.setState({ loading: false, submitted: true , showForm: false});
        }
      })
      .catch(console.error);

  }
  render () {
    return (
      <section className='subscription'>
        <div className='container'>
          <div className='row'>
            <div className='col-m-6 subscription__left'>
              <h3>{this.props.subscriptionData.header}</h3>
              <p>{this.props.subscriptionData.subHeader}</p>
            </div>
            <div className='col-m-6 subscription__right'>
              <form onSubmit={this.handleButtonSubmit}>
                { this.state.showForm &&
                  <input type="email" name='email' id='email' aria-label="Email" placeholder="Email" required />
                }
                {
                  this.state.initial &&
                  <Button.Transparent type='submit' buttonText={this.props.subscriptionData.buttonText} />
                }
                {
                  this.state.loading &&
                  <Button.Transparent type='submit' buttonText='Just a moment...' />
                }
                {
                  this.state.submitted &&
                  <h3 className='subscription__thanks'>Thank You! </h3>
                }
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Subscription;
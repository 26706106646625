import React, { Component } from 'react';
import './ChallengeNavigation.scss';

import FoodIcon from './images/food.svg';
import GlobeIcon from './images/Globe.svg';
import FinanceIcon from './images/Finance.svg';
import BoltIcon from './images/Bolt.svg';
import WorkIcon from './images/Workforce.svg';

class ChallengeNavigation extends Component {

  state = {
    isActive: false
  };

  componentDidMount() {
    const selectedChallenge = (this.props.activeChallenge).replace(' ', '-');
    let activeChallenge = document.getElementById(selectedChallenge);
    activeChallenge.classList.add('active');
  }
  

  handleClick(e) {
    const activeChallenge = document.getElementsByClassName('challenge-nav__item active');
    activeChallenge[0].classList.remove('active');
    e.currentTarget.classList.add('active');
  }

  render() {
    return (
      <section className='challenge-nav'>
        <div className='container'>
          <div className='row challenge-nav__container'>
            <div id="Global-Health" onClick={(e) => { this.props.setCurrentChallenge('Global Health', e); this.handleClick(e) }} className='challenge-nav__item global'>
              <img src={GlobeIcon} alt='Globe' />
              <p >Health</p>
            </div>
            <div id='Food-Systems' onClick={(e) => { this.props.setCurrentChallenge('Food Systems', e); this.handleClick(e) }} className='challenge-nav__item food'>
              <img src={FoodIcon} alt='Food' />
              <p >Food & Agriculture</p>
            </div>
            <div id='Energy' onClick={(e) => { this.props.setCurrentChallenge('Energy', e); this.handleClick(e) }} className='challenge-nav__item energy'>
              <img src={BoltIcon} alt='Bolt' />
              <p >Energy</p>
            </div>
            <div id='Workforce-Development' onClick={(e) => { this.props.setCurrentChallenge('Workforce Development', e); this.handleClick(e) }} className='challenge-nav__item workforce'>
              <img src={WorkIcon} alt='Globe' />
              <p >Workforce Development</p>
            </div>
            <div id='Financial-Inclusion' onClick={(e) => { this.props.setCurrentChallenge('Financial Inclusion', e); this.handleClick(e) }} className='challenge-nav__item financial'>
              <img src={FinanceIcon} alt='Globe' />
              <p >Financial Inclusion</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ChallengeNavigation;

import React from 'react';
import './Introduction.scss';


const Introduction = (props) => (
  <section className='introduction-section'>
    <div className='container'>
      <p className='introduction-text'>
        {props.introData.introduction}
      </p>
    </div>
  </section>
);

export default Introduction;

import React from 'react';
import './ChallengeOutcomes.scss';

import Pencil from './Images/pencil.svg';
import Bulb from './Images/bulb.svg';
import Team from './Images/team.svg';
import Rocket from './Images/rocket.svg';


const ChallengeOutcomes = (props) => (
  <section className='challenge-outcomes-section'>
    <div className='container'>
      <div className='row'>
        <div className='col-m-12'>
          <h2> {props.outcomeData.header}</h2>
        </div>
        <div className='col-m-3'>
          <img src={Pencil} alt='pencil' />
          <p>
            {props.outcomeData.firstOutcome}
          </p>
        </div>
        <div className='col-m-3'>
          <img src={Team} alt='team' />
          <p>
            {props.outcomeData.secondOutcome}
          </p>
        </div>
        <div className='col-m-3'>
          <img src={Bulb} alt='bulb' />
          <p>
            {props.outcomeData.thirdOutcome}
          </p>
        </div>
        <div className='col-m-3'>
          <img src={Rocket} alt='rocket' />
          <p>
            {props.outcomeData.fourthOutcome}
          </p>
        </div>
      </div>
    </div>

  </section>
);

export default ChallengeOutcomes;

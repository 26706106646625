import React, { Component } from 'react';
import './HeaderNav.scss';
import * as Button from '../../Button/Button';
import Logo from './Images/logo.png';

class HeaderNav extends Component {
  makeNavSticky = () => {
    const navbar = document.querySelector('.nav');
    const phantomNav = document.querySelector('.phantom-nav');
    const sticky = navbar.offsetTop;

    if (window.pageYOffset > sticky) {
      navbar.classList.add('nav--sticky');
      phantomNav.classList.add('visible');
    } else {
      navbar.classList.remove('nav--sticky');
      phantomNav.classList.remove('visible');
    }
  };
  componentDidMount() {
    window.addEventListener('scroll', this.makeNavSticky);
  }

  showDropdownMenu() {
    document.getElementById('myDropdown').classList.toggle('show');
  }

  handleClick() {
    window.location= '/';
  }

  render() {
    const isTransparent = this.props.transparent;
    return (
      <div>
        <nav className='phantom-nav'></nav>
        <nav className={isTransparent ? 'nav' : 'nav nav--white-background'}>
          <div className='nav__logo'>
            <a href='/'>
              <img src={Logo} alt='acumen academy logo' />
            </a>
          </div>
          <input id='nav__menu-toggle' type='checkbox' />
          <label className='nav__menu-button-container' htmlFor='nav__menu-toggle'>
            <div className='nav__menu-button' />
          </label>
          <div className='nav__menu'>
            <div className='nav__menu-dropdown'>
              <button onMouseOver={this.showDropdownMenu} >ABOUT &#x25BE;</button>
              <div id='myDropdown' className='dropdown-content'>
                <a href='/competition'>competition</a>
                <a href='/who-we-are'>who we are</a>
              </div>
            </div>
            <a className='nav__menu--desktop' href='/challenge-areas'>Challenge Areas</a>
            <a className='nav__menu--desktop' href='/faqs'>faqs</a>
            <a className='nav__menu--mobile' href='/competition'>competition</a>
            <a className='nav__menu--mobile' href='/who-we-are'>Who we are</a>
            <a className='nav__menu--mobile' href='/challenge-areas'>Challenge areas</a>
            <a className='nav__menu--mobile' href='/faqs'>Faqs</a>

          </div>
          {
            isTransparent ? (<Button.Transparent onClick={this.handleClick} buttonText='registration closed' />)
              : (<Button.Blue classes='get-started' onClick={this.handleClick} buttonText='registration closed' />)
          }
        </nav>
      </div>
    );
  }
}

export default HeaderNav;

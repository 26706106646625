import React, {Component} from 'react';

import axios from 'axios'; 

import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Spinner from '../../Common/Spinner/Spinner';
import Questions from './components/Questions/Questions';

export default class Faqs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const competitionPageUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchFrequentlyAskedQuestionsData';

    axiosInstance
      .get(competitionPageUrl)
      .then((result) => {
        this.setState({
          data: result.data.data.pageLayoutCollection.items[0].contentModulesCollection.items
        })
      })
      .catch(console.error);
  }

  render() {
    if (this.state.data.length > 0) {
      return (
        <div>
          < Header />
          < Questions faqData={this.state.data} />
          < Footer />
        </div>
      )
    }
    return ( <Spinner />)
  }
}

import React from 'react';
import './Partners.scss';

import BigIdeas from './Images/big-ideas.svg';
import MitIdeas from './Images/mit.svg';
import GlobalSocialInnovation from './Images/fowler-global.svg';
import Innovation from './Images/innovation.svg';

const Partners = () => (
  <section className='partners-section'>
    <div className='container'>
      <div className='row'>
        <div className='col-m-3 mit-ideas'>
          <img src={MitIdeas} alt='MIT Ideas logo' />
        </div>
        <div className='col-m-3 big-ideas'>
          <img src={BigIdeas} alt='Big Ideas logo' />
        </div>
        <div className='col-m-3'>
          <img src={GlobalSocialInnovation} alt='Global Social Innovation logo' />
        </div>
        <div className='col-m-3 innovation'>
          <img src={Innovation} alt='Innovation in action logo' />
        </div>
      </div>
    </div>
  </section>
);

export default Partners;

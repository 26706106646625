import React from 'react';
import './UniList.scss';

import axios from 'axios'; 

import * as Button from '../../../../Common/Button/Button';
import CountryList from './CountryList';
import Modal from '../../../../Common/Modal/Modal';
import Spinner from '../../../../Common/Spinner/Spinner';

import BigIdeas from './big-ideas.svg';
import MitIdeas from './mit.svg';
import GlobalSocialInnovation from './fowler-global.svg';
import Innovation from './innovation.svg';

class UniList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      onCampusCompetition: false,
      virtualCompetition: true,
      canRedirect: false,
      competition: '',
      competitionUrl: '',
      competitionImage: '',
      value: 'DEFAULT',
      enrolled: false,
      otherDegree: false,
      show: false,
    }
  }


  // When i click a university i want the challenge, url and image related to that challenge.
  triggerOnCampusCompetition = (university) => {
    let image;
    let url;
    switch (university) {
      case 'University of Michigan':
        image =  Innovation
        url = 'https://acumen.fyi/umich'
        break;
      case 'Big Ideas':
        image = BigIdeas
        url = 'https://acumen.fyi/big-ideas'
        break;
      case 'Massachusetts Institute of Technology':
        image = MitIdeas
        url = 'https://acumen.fyi/mit-ideas'
        break;
      case 'Fowler Global Social Innovation Challenge':
        image = GlobalSocialInnovation
        url = 'https://acumen.fyi/fowler-gsic'
        break;
      default:
        break;
    }

    this.setState({
      onCampusCompetition: true,
      competition: university,
      competitionImage: image,
      competitionUrl: url
    })
  }

  triggerVirtualCompetition = () => {
    this.setState({
      onCampusCompetition: false,
      virtualCompetition: true
    })
  }

  toggleEnrollmentStatus = (event) => {
    this.setState({
      enrolled: event.target.value
    });
  }

  showOtherDegreeLevelInputBox = (event) => {
    const show = event.target.value === 'Other' ? true : false;
    this.setState({ otherDegree: show })
  }

  showOtherSectorInputBox = (event) => {
    const show = event.target.value === 'other' ? true : false;
    this.setState({ otherSector: show})
  }

  handleButtonSubmit = (e) => {

    this.setState({show: true});

    const formData = new FormData(e.target)
    let data = {}

    e.preventDefault()

    for (let entry of formData.entries()) {
      data[entry[0]] = entry[1]
    }
    
    data = {user: data};

    const formDataUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/submitApplicationFormData';

    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    axiosInstance
      .post(formDataUrl, data)
      .then((result) => {
        window.location = '/thank-you';
      })
      .catch(console.error);
  }

  redirectToChallenge = (redirectUrl) => {
      window.location = redirectUrl;
  }

  selectOption = (event) => {
    this.setState({ value: event.target.value });

    if (event.target.value === 'Not listed') {
      this.triggerVirtualCompetition();
    }
    if (event.target.value !== 'DEFAULT' && event.target.value !== 'Not listed') {
      this.setState({
        virtualCompetition: false
      })
      this.triggerOnCampusCompetition(event.target.value);
    }
  }

  render() {
    return (
      <>
        {/* <p>Do you see your university on this list?</p>

        <select onChange={this.selectOption} defaultValue="DEFAULT">
          <option value="DEFAULT" disabled>
            Select...
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Australian Catholic University
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Bethel University
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            CETYS Universidad, Campus Tijuana
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Davis College Akilah Campus
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Duke University
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Ewha University-Industry Collaboration Foundation Center for
            Entrepreneurship
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Fordham University
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Haverford College
          </option>
          <option value="Big Ideas">Hebrew University of Jerusalem</option>
          <option value="Fowler Global Social Innovation Challenge">
            Heritage Christian College
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Instituto de Estudios Superiores de Administración- Venezuela
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            International Islamic University Malaysia
          </option>
          <option value="Big Ideas">Makerere University (Uganda)</option>
          <option value="Massachusetts Institute of Technology">
            Massachusetts Institute of Technology
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            National University of Management (International College)
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            NewSchool of Architecture & Design
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            North Dakota State University
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Rollins College
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            San Diego State University
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Swarthmore College
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            The Maharaja Sayajirao University of Baroda
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Trinity University Dublin
          </option>
          <option value="Big Ideas">UC Berkeley</option>
          <option value="Big Ideas">UC Davis</option>
          <option value="Big Ideas">UC Irvine</option>
          <option value="Big Ideas">UCLA</option>
          <option value="Big Ideas">UC Merced</option>
          <option value="Big Ideas">UC Riverside</option>
          <option value="Big Ideas">UC San Diego</option>
          <option value="Big Ideas">UC San Francisco</option>
          <option value="Big Ideas">UC Santa Barbara</option>
          <option value="Big Ideas">UC Santa Cruz</option>
          <option value="Fowler Global Social Innovation Challenge">
            Universidad de Antioquia
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Universidad de Desarrollo
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Universidad Francisco de Vitoria
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Universidad de las Americas- Ecuador
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            Universidad del Pacifico
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            University College of Technology Sarawak
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            University of Kentucky
          </option>
          <option value="University of Michigan">University of Michigan</option>
          <option value="Fowler Global Social Innovation Challenge">
            University of Minnesota
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            University of San Diego
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            University of St Thomas
          </option>
          <option value="Fowler Global Social Innovation Challenge">
            University of Texas at Austin
          </option>
          <option value="Not listed">Not listed</option>
        </select> */}

        {this.state.onCampusCompetition && (
          <div>
            <p>
              Great! This social innovation competition is open to students from
              your university:
            </p>
            <div className="half-width">
              <img
                src={this.state.competitionImage}
                alt={this.state.competition}
              />
            </div>
            <form>
              <p>
                Register with our university partner competition on their
                website
              </p>
              <Button.Blue
                onClick={e =>
                  this.redirectToChallenge(this.state.competitionUrl, e)
                }
                buttonText={"go to " + this.state.competition}
              />
            </form>
          </div>
        )}
        {this.state.virtualCompetition && (
          <div>
            <Modal
              show={this.state.show}
              handleClose={this.hideModal}
              children={
                <div className="container">
                  <div className="row">
                    <div className="col-m-12">
                      <p>Just a moment please...</p>
                      <Spinner />
                    </div>
                  </div>
                </div>
              }
            />
            <p>
              Our virtual competition will provide you with what you need to
              develop your idea and compete up to $20,000 per team in funding.
            </p>
            <a href="/faqs" target="_blank" rel="noopener noreferrer">
              See FAQS to learn more
            </a>
            <h3>Register</h3>
            <p>
              Share your information with us to enter the virtual competition
            </p>
            <form onSubmit={this.handleButtonSubmit}>
              <div className="competition-input-form first bottom-padding">
                <label htmlFor="first-name">First Name</label>
                <br />
                <input name="first-name" id="first-name" type="text" required />
              </div>
              <div className="competition-input-form first">
                <label htmlFor="last-name">Last Name</label>
                <br />
                <input name="last-name" id="last-name" type="text" required />
              </div>
              <div className="competition-input-form bottom-padding">
                <label htmlFor="email">Email</label>
                <br />
                <input name="email" id="email" type="email" required />
              </div>
              <div className="competition-input-form last">
                <p>Are you enrolled at a higher education institution?</p>
                <div className="">
                  <input
                    onClick={this.toggleEnrollmentStatus}
                    type="radio"
                    id="yes"
                    name="enrollment-status"
                    value="yes"
                    required
                  />
                  <label htmlFor="yes">Yes</label>
                </div>
                <div className="bottom-padding">
                  <input
                    onClick={this.toggleEnrollmentStatus}
                    type="radio"
                    id="no"
                    name="enrollment-status"
                    value="no"
                  />
                  <label htmlFor="no">No</label>
                </div>
              </div>
              {this.state.enrolled === "yes" && (
                <div className="competition-input-form last bottom-padding">
                  <label htmlFor="university">
                    What is the name of the higher education institution you're
                    currently enrolled in?
                  </label>
                  <br />
                  <input name="university" id="university" required />
                </div>
              )}
              <div className="competition-input-form bottom-padding full-width">
                <label htmlFor="country-residence">Country of residence</label>
                <br />
                <CountryList countryId="country-residence" />
              </div>
              <div className="competition-input-form last">
                <p>What level of degree are you pursuing?</p>
                <div>
                  <input
                    onClick={this.showOtherDegreeLevelInputBox}
                    type="radio"
                    id="bachelors"
                    name="degree-level"
                    value="Bachelor's Degree"
                    required
                  />
                  <label for="bachelors">Bachelor's Degree</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherDegreeLevelInputBox}
                    type="radio"
                    id="masters"
                    name="degree-level"
                    value="Master's Degree/Professional (e.g. JD, MD, MA, MS, MEng, MBA, MEd, etc)"
                  />
                  <label for="masters">Master's / Graduate's Degree</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherDegreeLevelInputBox}
                    type="radio"
                    id="doctorate"
                    name="degree-level"
                    value="Doctoral Degree (e.g. PhD, EdD, Dr)"
                  />
                  <label for="doctorate">Doctorate Degree</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherDegreeLevelInputBox}
                    type="radio"
                    id="degree-levele-other"
                    name="degree-level"
                    value="Other"
                  />
                  <label for="degree-level-other">
                    Other (please specify){" "}
                  </label>
                </div>
                <div className="bottom-padding">
                  <input
                    onClick={this.showOtherDegreeLevelInputBox}
                    type="radio"
                    id="notapplicable"
                    name="degree-level"
                    value="N/A"
                  />
                  <label for="notapplicable">
                    N/A: I am not currently enrolled at a higher education
                    institution
                  </label>
                </div>
              </div>
              {this.state.otherDegree && (
                <div className="competition-input-form last bottom-padding">
                  <label htmlFor="other-bachelors">Other</label>
                  <br />
                  <input name="other-bachelors" id="other-bachelors" required />
                </div>
              )}
              <div className="competition-input-form full-width bottom-padding">
                <label htmlFor="gender">Gender</label>
                <br />
                <select
                  id="gender"
                  name="gender"
                  defaultValue="DEFAULT"
                  required
                >
                  <option value="DEFAULT" disabled>
                    Please select...
                  </option>
                  <option value="Woman">Woman</option>
                  <option value="Man">Man</option>
                  <option value="Genderqueer/Non-binary">
                    Genderqueer / Non-binary
                  </option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </select>
              </div>
              <div className="competition-input-form last">
                <p>
                  Is this your first time participating in a student innovation
                  competition?
                </p>
                <div>
                  <input
                    type="radio"
                    id="participation-yes"
                    name="participation"
                    value="true"
                    required
                  />
                  <label for="participation-yes">Yes</label>
                </div>
                <div className="bottom-padding">
                  <input
                    type="radio"
                    id="particiaption-no"
                    name="participation"
                    value="false"
                  />
                  <label for="participation-no">No</label>
                </div>
              </div>
              <div className="competition-input-form last bottom-padding">
                <label htmlFor="participation-outcome">
                  What do you hope to learn from participating in this
                  Challenge?
                </label>
                <br />
                <input
                  input
                  type="text"
                  name="participation-outcome"
                  id="participation-outcome"
                  required
                />
              </div>
              <div className="competition-input-form last bottom-padding">
                <p>
                  Which sector are are you interested in developing a social
                  innovation for throughout this online course?
                </p>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="energy"
                    name="sector"
                    value="Energy"
                    required
                  />
                  <label for="energy">Energy</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="financial-inclusion"
                    name="sector"
                    value="Financial Inclusion"
                  />
                  <label for="financial-inclusion">Financial Inclusion</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="food-and-agriculture"
                    name="sector"
                    value="Food and Agriculture"
                  />
                  <label for="food-and-agriculture">Food and Agriculture</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="health"
                    name="sector"
                    value="Health"
                  />
                  <label for="health">Health</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="workforce-development"
                    name="sector"
                    value="Workforce Development"
                  />
                  <label for="worforce-development">
                    Workforce Development
                  </label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="sector-other"
                    name="sector"
                    value="other"
                  />
                  <label for="sector-other">Other (please specify)</label>
                </div>
                <div>
                  <input
                    onClick={this.showOtherSectorInputBox}
                    type="radio"
                    id="sector-dont-know"
                    name="sector"
                    value="I don\'t know yet"
                  />
                  <label for="sector-dont-know">I don't know yet</label>
                </div>
              </div>
              {this.state.otherSector && (
                <div className="competition-input-form last bottom-padding">
                  <label htmlFor="other">Other</label>
                  <br />
                  <input name="other" id="other" required />
                </div>
              )}
              <div className="competition-input-form last bottom-padding">
                <label htmlFor="website">
                  If your social enterprise or initiative already exists, what
                  is your website?
                </label>
                <br />
                <input name="website" id="website" type="text" />
              </div>
              <div className="competition-input-form bottom-padding full-width">
                <label htmlFor="country-applicable">
                  Select the country that is most applicable to the business
                  model you will develop.
                </label>
                <br />
                <CountryList countryId="country-applicable" />
              </div>
              <div className="competition-input-form last bottom-padding">
                <p>
                  I agree to the{" "}
                  <a
                    href="https://ra.acumenacademy.org/terms-and-Conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </p>
                <div>
                  <input
                    type="radio"
                    id="terms-yes"
                    name="terms"
                    value="yes"
                    required
                  />
                  <label for="terms-yes">Yes</label>
                </div>
              </div>
              <div className="competition-input-form last bottom-padding">
                <p>
                  I understand that the Terms and Conditions require at least 2
                  team members to take the Lean Startup course.{" "}
                </p>
                <div>
                  <input
                    type="radio"
                    id="lean-startup-terms-yes"
                    name="lean-startup-terms"
                    value="yes"
                    required
                  />
                  <label for="lean-startup-terms-yes">Yes</label>
                </div>
              </div>
              <Button.Blue type="submit" buttonText="submit registration" />
            </form>
          </div>
        )}
      </>
    );
  }
}

export default UniList;

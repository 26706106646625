import React, { Component } from 'react';
import './TermsAndConditions.scss';

import axios from 'axios';
import Markdown from 'react-markdown';

import Spinner from '../../Common/Spinner/Spinner';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';

export default class TermsAndConditions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const termsAndConditionsUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchTermsAndConditionsPageData';

    axiosInstance
      .get(termsAndConditionsUrl)
      .then((result) => {
        this.setState({
          data: result.data.data.pageLayoutCollection.items[0].contentModulesCollection.items[0]
        })
      })
      .catch(console.error);
  }

  render() {
    if (this.state.data.header) {
      return (
        <div>
          <Header transparency={false} />
          <div className='container terms-and-conditions'>
            <div className='row'>
              <div className='col-m-12'>
                <h1 className='text-center'>
                  {this.state.data.header}
                </h1>
              </div>
              <div className='container'>
                <div className='row'>
                  <div className='col-m-12'>
                    <div className='terms'>
                      <Markdown
                        escapeHtml={true}
                        source={this.state.data.terms}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )
    }
    return (
      <Spinner />
    )
  }
}

import React from 'react';
import './VirtualCompetition.scss';

import Competition from '../../../../Common/Competition/Competition';

const VirtualCompetition = (props) => {

  const competitionData = {
    header: 'Virtual Competition',
    image: props.virtual.competitionImage.url,
    imageAltText: 'Man and lady looking at a laptop',
    ctaHeader: 'Join Us Online',
    ctaText: props.virtual.joinUsOnline,
    checklists: props.virtual.competitionChecklist
  }
  return (
    <section id='virtual-competition' className='virtual-competition'>
      < Competition competitionData={competitionData} />
    </section>
  );
}

export default VirtualCompetition;

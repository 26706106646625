import React from 'react';
import './AdditionalResources.scss';

import Card from '../../../Card/Card';

const renderCards = (resources) => {
  const resourcesLength = resources.length;
  return resources.map((resource, index) => (
    <Resource length={resourcesLength} data={resource} index={index} />
  ));
}

const Resource = props => {
  if (props.index === 1) {
    return (
      <a className='col-m-6 additional-resources__featured' href={props.data.resourceUrl} target='_blank' rel='noopener noreferrer'>
        <img src={props.data.resourceImage.url} alt='' />
        <div className='featured-text top'>{props.data.resourceType}</div>
        <div className='featured-text bottom'>
          <h2>
            {props.data.resourceTitle}
          </h2>
        </div>
      </a>
    )
  }
  return (
    <a className='col-m-3' href={props.data.resourceUrl} target='_blank' rel='noopener noreferrer'>
      <Card title={props.data.resourceTitle}  image={props.data.resourceImage.url} imageText={props.data.resourceType} />
    </a>
  )
}
const AdditionalResources = props => {
  return (
    <section className='additional-resources'>
      <div className='container'>
        <div className='row'>
          <div className='col-m-12'>
            <h2>
              Additional Resources
            </h2>
          </div>
         {renderCards(props.resources.additionalResourcesCollection.items)}
        </div>
      </div>
    </section>
  )
}

export default AdditionalResources

import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducers from './reducers/rootReducer';
import './index.scss';
import Home from './components/Pages/Home/Home';
import About from './components/Pages/About/About';
import Faqs from './components/Pages/Faqs/Faqs';
import WhoWeAre from './components/Pages/WhoWeAre/WhoWeAre';
import ChallengeAreas from './components/Pages/ChallengeAreas/ChallengeAreas';
import GetStarted from './components/Pages/GetStarted/GetStarted';

import * as serviceWorker from './serviceWorker';
import ThankYou from './components/Pages/ThankYou/ThankYou';
import TermsAndConditions from './components/Pages/TermsAndConditions/TermsAndConditions';

const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div>
        <Route exact path="/" component={Home} />
        <Route path="/competition" component={About} />
        <Route path="/faqs" component={Faqs} />
        <Route path="/who-we-are" component={WhoWeAre} />
        <Route path="/challenge-areas" component={ChallengeAreas} />
        <Route path="/get-started" component={GetStarted} />
        <Route path="/thank-you" component={ThankYou} />
        <Route path="/terms-and-conditions" component={TermsAndConditions} />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

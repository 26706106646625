import React from 'react';
import './ChallengeIntro.scss';

const ChallengeIntro = props => {

  return (
    <section className='challenge-intro'>
      <div className='container'>
        <div className='row'>
          <div className='col-m-7'>
            <h2>
              {props.data.title}
            </h2>
            <p>
              {props.data.challengeModulesCollection.items[0].introductionText.json.content[0].content[0].value}
            </p>
          </div>
          <div className='col-m-5'>
            <img src={props.data.challengeModulesCollection.items[0].challengeImage.url} alt={props.data.challengeModulesCollection.items[0].challengeImage.description} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ChallengeIntro

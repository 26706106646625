import React, {Component} from 'react';
import './ChallengeAreas.scss';

import axios from 'axios'; 
 
import ChallengeNavigation from './components/ChallengeNavigation/ChallengeNavigation';
import * as Challenge from '../../Common/Challenge/Challenge';
import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Spinner from '../../Common/Spinner/Spinner';

class ChallengeAreas extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowing: 'Global Health',
      globalHealthData: {},
      financialInclusionData: {},
      energyData: {},
      foodSystemsData: {},
      WorkforceDevelopmentData: {}
    };
  }

  setCurrentChallenge = (e) => {
    this.setState({isShowing: e})
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const globalHealthUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchGlobalHealthData';
    const foodSystemsthUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchFoodSystemsData';
    const energyUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchEnergyData';
    const workforceDevelopmentUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchWorkforceDevelopmentData';
    const financialInclusionUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchFinancialInclusionData';

    axiosInstance
      .get(globalHealthUrl)
      .then((result) => {
        this.setState({
          globalHealthData: result.data.data.challengeAreasLayout
        })
      })
      .catch(console.error);

    axiosInstance
      .get(foodSystemsthUrl)
      .then((result) => {
        this.setState({
          foodSystemsData: result.data.data.challengeAreasLayout
        })
      })
      .catch(console.error);

    axiosInstance
      .get(energyUrl)
      .then((result) => {
        this.setState({
          energyData: result.data.data.challengeAreasLayout
        })
      })
      .catch(console.error);

    axiosInstance
      .get(workforceDevelopmentUrl)
      .then((result) => {
        this.setState({
          WorkforceDevelopmentData: result.data.data.challengeAreasLayout
        })
      })
      .catch(console.error);

    axiosInstance
      .get(financialInclusionUrl)
      .then((result) => {
        this.setState({
          financialInclusionData: result.data.data.challengeAreasLayout
        })
      })
      .catch(console.error);

    function getUrlVars() {
      var vars = {};
      window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
      });
      return vars;
    }

    const challengeFromUrl = getUrlVars()["challenge"];

    if (challengeFromUrl) {
      this.setState({ isShowing: challengeFromUrl.replace('+', ' ')})
    }
  }

  renderCurrentChallenge = () => {
    switch (this.state.isShowing) {
      case 'Global Health':
        return <Challenge.GlobalHealth challengeData={this.state.globalHealthData} setCurrentChallenge={this.setCurrentChallenge}/>;
      case 'Food Systems':
        return <Challenge.FoodSystems challengeData={this.state.foodSystemsData} setCurrentChallenge={this.setCurrentChallenge}/>;
      case 'Energy':
        return <Challenge.Energy challengeData={this.state.energyData} setCurrentChallenge={this.setCurrentChallenge} />;
      case 'Workforce Development':
        return <Challenge.WorkforceDevelopment challengeData={this.state.WorkforceDevelopmentData} setCurrentChallenge={this.setCurrentChallenge}/>;
      case 'Financial Inclusion':
        return <Challenge.FinancialInclusion challengeData={this.state.financialInclusionData} setCurrentChallenge={this.setCurrentChallenge}/>;
      default: 
        break;
    }
  }

  render() {
    if (
      this.state.globalHealthData.challengeModulesCollection &&
      this.state.financialInclusionData.challengeModulesCollection &&
      this.state.energyData.challengeModulesCollection &&
      this.state.WorkforceDevelopmentData.challengeModulesCollection &&
      this.state.foodSystemsData.challengeModulesCollection
      ) {
      return (
        <div>
          < Header transparency={false} />
          <div className='container'>
            <div className='row'>
              <div className='col-m-12'>
                <h1 className='challenge-areas__header'>Our Challenge Areas</h1>
              </div>
            </div>
          </div>
          <ChallengeNavigation activeChallenge={this.state.isShowing} setCurrentChallenge={this.setCurrentChallenge} />
          {this.renderCurrentChallenge()}
          < Footer />
        </div>
      );
    }
    return (
      <Spinner />
    );
  }
}

export default ChallengeAreas;
import React, {Component} from 'react';
import './OtherChallengeAreas.scss';

import FoodIcon from './images/food.svg';
import GlobeIcon from './images/Globe.svg';
import FinanceIcon from './images/Finance.svg';
import BoltIcon from './images/Bolt.svg';
import WorkIcon from './images/Workforce.svg';

class OtherChallengeAreas extends Component {

  state = {
    currentChallenge: this.props.currentChallenge
  };

  handleClick = (e) => {
    const clickedChallenge = document.getElementsByClassName(`challenge-nav__item ` + e);
    clickedChallenge[0].click();
    this.setState({ currentChallenge: clickedChallenge[0].innerText })
    
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };
    scrollToTop();
  }

  render() {
    return (
      <section className='other-challenge-areas'>
        <h2 className='text-center'>Other Challenge Areas</h2>
        <div className='other-challenge-areas__container'>
          {
            this.state.currentChallenge !== 'Health' &&
            <div onClick={(e) => this.handleClick('global', e)} className='other-challenge-areas__challenge'>
              <img src={GlobeIcon} alt='Globe' />
              <p>Health</p>
            </div>
          }
          {
            this.state.currentChallenge !== 'Food and Agriculture' &&
            <div onClick={(e) => this.handleClick('food', e)} className='other-challenge-areas__challenge'>
              <img src={FoodIcon} alt='Food' />
              <p>Food & Agriculture</p>
            </div>
          }
          {
            this.state.currentChallenge !== 'Energy' &&
            <div onClick={(e) => this.handleClick('energy', e)} className='other-challenge-areas__challenge'>
              <img src={BoltIcon} alt='Bolt' />
              <p>Energy</p>
            </div>
          }
          {
            this.state.currentChallenge !== 'Workforce Development' &&
            <div onClick={(e) => this.handleClick('workforce', e)} className='other-challenge-areas__challenge'>
              <img src={WorkIcon} alt='Globe' />
              <p>Workforce Development</p>
            </div>
          }
          {
            this.state.currentChallenge !== 'Financial Inclusion' &&
            <div onClick={(e) => this.handleClick('financial', e)} className='other-challenge-areas__challenge'>
              <img src={FinanceIcon} alt='Globe' />
              <p>Financial Inclusion</p>
            </div>
          }
        </div>
      </section>
    )
  }
}
  

export default OtherChallengeAreas;

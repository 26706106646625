import React, { Component } from 'react';
import Markdown from "react-markdown";
import './ThankYou.scss';

import axios from 'axios'; 

import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';

import * as Button from '../../Common/Button/Button';
import Spinner from '../../Common/Spinner/Spinner';

const redirectTo = url => {
  window.location = url;
}

export default class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });

    const thankYouUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchThankYouPageData';

    axiosInstance
      .get(thankYouUrl)
      .then((result) => {
        this.setState({
          data: result.data.data.pageLayoutCollection.items[0].contentModulesCollection.items[0]
        })
      })
      .catch(console.error);
  }
  render() {
    if (this.state.data.header) {
      return (
        <div>
          <Header transparency={false} />
          <div className="container">
            <div className="row">
              <div className=" text-center col-m-12 thank-you-page">
                <img src={this.state.data.logo.url} alt="" />
                <h1>{this.state.data.header}</h1>
                <p>
                  <Markdown
                    escapeHtml={true}
                    source={this.state.data.thankYouText}
                  />
                </p>
                <Button.TransparentBlue
                  onClick={e => redirectTo(this.state.data.redirectUrl, e)}
                  buttonText={this.state.data.buttonText}
                />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }
    return <Spinner />
  }
}

import React from 'react';
import './Modal.scss';

const Modal = ({ show, children }) => {
  const showHideClassName = show ? 'modal' : 'modal hide';

  return (
    <div className={showHideClassName}> 
      <section className='modal__main'>
        {children}
      </section>
    </div>
  )
}

export default Modal

import React from 'react';
import './FooterNav.scss';

import Logo from '../../Header/HeaderNav/Images/logo.png';

const FooterNav = () => {
  return (
    <nav className='footer-nav'>
      <div className='footer-nav__menu'>
        <a href='/competition'>About</a>
        <a href='/challenge-areas'>Challenge areas</a>
        <a href='/faqs'>Faqs</a>
        <a href='/terms-and-conditions'>Terms & Conditions</a>
      </div>
      <div className='footer-nav__logo'>
        <a href='/'>
          <img src={Logo} alt='acumen academy logo' />
        </a>
      </div>
    </nav>
  );
}

export default FooterNav;

import React, { Component } from 'react';
import './Card.scss';

import * as Button from '../Button/Button';

class Card extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigationUrl: ''
    };
  }

  componentDidMount() {
    this.setState({
      navigationUrl: this.props.url
    })
  }
  

  handleClick = () => {
    window.location = this.state.navigationUrl;
  }

  renderButton = (button) => {
    if (button) {
      return <Button.TransparentBlue onClick={this.handleClick} buttonText='learn more' buttonSize='small' />
    }
  }

  render () {
    return (
      <div className='card'>
      <div className='card__image-container'>
        <div id='overlay'></div>
        <img className='card__image' src={this.props.image} alt='Avatar' />
      </div>
        <div className='card__image-text'>{this.props.imageText || ''}</div>
        <div className='card__text-area'>
          <p className='card__subject'>{this.props.subject || ''}</p>
          <h4 className='card__title'><b>{this.props.title}</b></h4>
          <p className='card__text'>
            {this.props.text}
          </p>
          {this.renderButton(this.props.button)}
        </div>
      </div>
    );
  }
}

export default Card;

import React, { Component } from 'react';

import { CSSTransition } from 'react-transition-group';

import './AccordionSection.scss';

class AccordionSection extends Component {

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this;

    return (
      <div className='accordion-section'>
        <div className={!isOpen ? 'accordion' : 'accordion active'} onClick={onClick} >
          {label}
        </div>
          <CSSTransition
            in={isOpen}
            timeout={300}
            classNames="alert"
            unmountOnExit
          >
            {this.props.children}
          </CSSTransition>
      </div>
    );
  }
}

export default AccordionSection;

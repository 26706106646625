import React from 'react';
import './Spinner.scss';
import Acumen from './acumen.gif';

export default function Spinner() {
  return (
    <div className='container spinner'>
      <div className='row'>
        <div className='col-m-12'>
          <img src={Acumen} alt='Acumen'/>
        </div>
      </div>
    </div>
  )
}

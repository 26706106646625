import React, { Component } from 'react';
import './About.scss';

import axios from 'axios'; 

import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import CompetitionIntro from './components/CompetitionIntro/CompetitionIntro';
import CampusCompetitions from './components/CampusCompetitions/CampusCompetitions';
import Partners from '../../Common/Partners/Partners';
import VirtualCompetition from './components/VirtualCompetition/VirtualCompetition';
import Spinner from  '../../Common/Spinner/Spinner';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentDidMount() {
    const axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    });
    
    const competitionPageUrl = 'https://us-central1-rockefeller-microservice.cloudfunctions.net/fetchCompetitionPageData';

    axiosInstance
      .get(competitionPageUrl)
      .then((result) => {
        this.setState({
          data: result.data.data.pageLayoutCollection.items[0].contentModulesCollection.items
        })
      })
      .catch(console.error);
  }
  render() {
    if (this.state.data.length) {
      return (
        <div>
          < Header transparency={false} />
          < CompetitionIntro introData={this.state.data[0]}  virtual={this.state.data[2]} onCampus={this.state.data[1]}/>
          < CampusCompetitions onCampus={this.state.data[1]} />
          < Partners />
          < VirtualCompetition virtual={this.state.data[2]} />
          < Footer />
        </div>
      );
    }
    return (
      <Spinner />
    );
  }
}

export default About;

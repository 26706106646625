import React from 'react';
import './GetStarted.scss';

import Header from '../../Common/Header/Header';
import Footer from '../../Common/Footer/Footer';
import Registration from './components/Registration/Registration';

const GetStarted = props => {
  return (
    <div>
      < Header />
      <section className='get-started-intro'>
        <div className='container'>
          <div className='row'>
            <div className='col-m-12 get-started-intro__container'>
              <h1>Registration Closed!</h1>
              {/* <p>See below if you qualify for our virtual competition or with one of our university competition partners.</p> */}
              {/* <Registration /> */}
            </div>
          </div>
        </div>
      </section>
      < Footer />
    </div>
  )
}

export default GetStarted

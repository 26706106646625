import * as React from 'react';
import './Hero.scss';
import * as Button from '../.../../../../../Common/Button/Button';
import Header from '../.../../../../../Common/Header/Header';

class Hero extends React.Component {

  handleClick() {
    window.location = '/';
  }
  render() {
    return (
      <section className='hero-section'>
        <Header transparency={true} />
        <div className='hero-section__text'>
          <div className='container'>
            <div className='row'>
              <div className='col-m-10'>
                <h1>{this.props.heroData.heroHeader || ''}</h1>
                <div className='row'>
                  <div className='col-m-8'>
                    <p>{this.props.heroData.heroSubHeader || ''}</p>
                  </div>
                </div>
                <Button.Blue onClick={this.handleClick} buttonText='registration closed' buttonSize='large' />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;

import React from 'react';
import classNames from 'classnames';
import './Button.scss';

export const TYPES = {
  TRANSPARENT: 'btn--transparent',
  BLUE: 'btn--blue',
  TRANSPARENTBLUE: 'btn--transparent-blue'
};

export const SIZES = {
  SMALL: 'btn--small',
  MEDIUM: 'btn--medium',
  LARGE: 'btn--large',
};

const BaseButton = (props) => {
  const buttonSize = props.buttonSize ? SIZES[props.buttonSize.toUpperCase()] : SIZES.MEDIUM;
  const classes = classNames('btn', props.buttonType, buttonSize, props.classes);

  return <button onClick={props.onClick} type={props.type || 'button'} className={classes}>{props.buttonText}</button>;
};

export const Transparent = props => (
  <BaseButton {...props} buttonType={TYPES.TRANSPARENT} />
);

export const Blue = props => (
  <BaseButton {...props} buttonType={TYPES.BLUE} />
);

export const TransparentBlue = props => (
  <BaseButton {...props} buttonType={TYPES.TRANSPARENTBLUE} />
);

import React from 'react';
import './ChallengeAreas.scss';

import Card from '../../../../Common/Card/Card';

const renderChallengeCards = (cards) => {
  return cards.map((card) => (
    <div className='col-m-5ths'>
      <Card title={card.challengeTitle} text={card.challengeText} button={card.buttonText} image={card.challengeImage.url} url={card.url}/>
    </div>
  ));
}


const ChallengeAreas = (props) => {
  return (
    <section className='challenge-areas'>
      <div className='container'>
        <div className='row'>
          <div className='col-m-12'>
            <h2>{props.challengeData.header}</h2>
            <p className='challenge-areas__subtitle'>
              {props.challengeData.subHeader}
            </p>
          </div>
        </div>
      </div>
      <div className='container challenges'>
        <div className='row'>
          {renderChallengeCards(props.challengeData.challengeCardsCollection.items)}
        </div>
      </div>
    </section>
  );
}

export default ChallengeAreas;
import React, {Component} from 'react';
import './Questions.scss';

import Accordion from '../../../../Common/Accordion/Accordion';

import Markdown from 'react-markdown';

const renderFrequentlyAskedQuestions = faqs => {
  return faqs.map((faq) => (
    <div label={faq.question}>
      <p>
        <Markdown
          escapeHtml={true}
          source={faq.answer}
        />
      </p>
    </div>
  ));
}

export default class Questions extends Component {
  render() {
    return (
      <section className='faqs'>
        <div className='container'>
          <div className='row'>
            <div className='col-m-12'>
              <h1>
                Frequently asked questions
            </h1>
              <Accordion>
                {renderFrequentlyAskedQuestions(this.props.faqData)}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
